/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/useRenovationLoan.css";
import parse from 'html-react-parser';
const useRenovationLoan = ({ dataJson }) => {
    const imgCDNURL = process.env.REACT_APP_CDN_IMG;
    const { featureheading, featurelist, featurenote } = dataJson;
    return (
        <>
            <Container className="useRenovationLoan">
                <Row>
                    <Col sm={12} md={12} lg={7}>
                        <div>
                            <h3 className="ways-text">{featureheading}</h3>
                        </div>
                        <div className="ways-list">
                        <ul>
                                    {featurelist.map((item, index) => (
                                        <li key={index}>{parse(item)}</li>
                                    ))}
                                </ul>
                            {featurenote}
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={5}>
                        <div>
                            <img src={imgCDNURL + "colage.webp"} className="colage-img img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default useRenovationLoan;