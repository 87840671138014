/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/Body.css";

const bannerNew = ({ dataJson }) => {
    const imgCDNURL = process.env.REACT_APP_CDN_IMG;
    const { heading,clicktext,clicktext2, before,after, whyChooseSunwest, tailoredTitle, tailoredPara, dedicatedTitle, dedicatedPara, dreamTitle, dreamPara, renovationfinancing_link,dreamParanew, readyTitle, readyPara, readyPara1, readyPara2 } = dataJson;
    return (
        <>
            <div className="features-section mt-5">
                <Container>

                    <Row className="featuresRow text-center mt-5">

                        <Col sm={12} md={12} lg={8}>
                            <Row className="featuresRow2">

                                <Col sm={12} md={12} lg={4}>
                                    <div className="features-text">
                                        <h4>{whyChooseSunwest}</h4>
                                    </div>
                                    <div>
                                        <img
                                            src={imgCDNURL + "ease-img.webp"}
                                            className="features-img pb-3"
                                            alt="Ease"
                                        />
                                    </div>
                                    <div>
                                        <p><span>{tailoredTitle}</span> <br />
                                           {tailoredPara}</p>
                                    </div>
                                </Col>
                                <Col sm={12} md={12} lg={4} className="vision-col">
                                    <div>
                                        <img
                                            src={imgCDNURL + "vision-img.webp"}
                                            className="features-img pb-3"
                                            alt="Vision"
                                        />
                                    </div>
                                    <div>
                                        <p><span>{dedicatedTitle}</span><br />
                                           {dedicatedPara}</p>
                                    </div>
                                </Col>
                                <Col sm={12} md={12} lg={4} className="customize-col">
                                    <div>
                                        <img
                                            src={imgCDNURL + "customize-img.webp"}
                                            className="features-img pb-3"
                                            alt="Customize"
                                        />
                                    </div>
                                    <div>
                                        <p><span>{dreamTitle}</span><br />
                                           {dreamPara}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={12} lg={4} className="renovationimg-col">
                            <a href={renovationfinancing_link} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={imgCDNURL + "reno-guide.webp"}
                                    className="renoGuide-img img-fluid"
                                    alt="RENOVATION FINANCING GUIDE"
                                />
                            </a>
                            <b><p className="click-text"><a href={renovationfinancing_link} target="_blank" rel="noopener noreferrer">{clicktext}</a> {clicktext2}</p></b>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div>
                                <div className="d-flex aftbefore-div justify-content-center">
                                    <h3 className="before">{before}</h3>
                                    <h3 className="after">{after}</h3>
                                </div>
                                <img
                                    src={imgCDNURL + "after-before.webp"}
                                    className="afterbefore-img img-fluid"
                                    alt="Renovation"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default bannerNew;