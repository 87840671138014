import React, { useCallback, useState } from "react";
import { CommenFooters } from "footer-component";

import "./index.css";

const CustomFooter = () => {
  return (
    <div className="footerbody">
    <CommenFooters type="swmc" showTacanewLogo={true} nydisclaimer={false} />
  </div>
  );
};

export default CustomFooter;
